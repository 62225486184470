<div class="container">
<div class="titulo">
    <span>
        <mat-icon class="mat-icon">domain</mat-icon>
            Datos Empresa
        </span>

</div>
    <mat-divider></mat-divider>
<div>
    <form #form="ngForm" (ngSubmit)="onSubmit(form)" autocomplete="off">
        <div class="columnascontenedor">
            <div class="trescolumnas">
                <mat-form-field class="col_izq">
                    <mat-label>Usuario</mat-label>
                    <input matInput type="text"
                           [(ngModel)]="nombreUsuario"

                           name="usuario" readonly>

                </mat-form-field>
            </div>

            <div class="trescolumnas">
                    <mat-form-field class="col_med">
                    <mat-label>Denominación social</mat-label>
                    <input matInput type="text" name="denominacionSocial" [(ngModel)]="denominacionSocial"
                           (change)="setModificable()" placeholder="Denominación Social" required>
                    <mat-error *ngFor="let error of errors.general">{{error}}</mat-error>
                </mat-form-field >
            </div>
             <div class="trescolumnas">
                <mat-form-field class="col_der">
                    <mat-label>CIF</mat-label>
                    <input matInput  [(ngModel)]="cif" placeholder="CIF" name="cif"
                           (change)="setModificable()"
                           required>
                    <mat-error *ngFor="let error of errors.general">{{error}}</mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="columnascontenedor">
               <div class="trescolumnas">
                <mat-form-field class="col_izq">
                    <mat-label>Provincia</mat-label>
                    <mat-select name="provincia" (selectionChange)="seleccionProvincia($event.value);setModificable()" [(ngModel)]="provincia">
                        <mat-option *ngFor="let provincia of provincias" [value]="provincia.nombreProvincia">
                            {{provincia.nombreProvincia}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngFor="let error of errors.general">{{error}}</mat-error>
                </mat-form-field>
            </div>
            <div class="trescolumnas">
                <mat-form-field class="col_med">
                    <mat-label>Población</mat-label>
                    <mat-select name="poblacion" (selectionChange)="seleccionMunicipio($event.value);setModificable()" [(ngModel)]="poblacion">
                        <mat-option *ngFor="let municipio of municipios" [value]="municipio.nombreMunicipio">
                            {{municipio.nombreMunicipio}}
                        </mat-option>

                    </mat-select>
                    <mat-error *ngFor="let error of errors.general">{{error}}</mat-error>
                </mat-form-field>
            </div>
            <div class="trescolumnas">
                <mat-form-field class="col_der">
                    <mat-label>CP</mat-label>
                    <input matInput  [(ngModel)]="cp" placeholder="Código Postal" name="cp"
                           (change)="setModificable()" required>
                    <mat-error *ngFor="let error of errors.general">{{error}}</mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="columnascontenedor">
            <mat-form-field class="direccion">
                <mat-label>Dirección, planta, número...</mat-label>
                <input matInput name="direccion" placeholder="Dirección" [(ngModel)]="direccion"
                       (change)="setModificable()" required>
                <mat-error *ngFor="let error of errors.general">{{error}}</mat-error>
            </mat-form-field>
        </div>
        <div class="columnascontenedor">
            <div class="doscolumnas">
                <mat-form-field class="col_izq">
                    <mat-label>Teléfono</mat-label>
                    <input  type="tel" matInput name="telefono" id="input-tel" [(ngModel)]="telefono" placeholder="Teléfono"
                            (change)="setModificable()" required>
                    <mat-error *ngFor="let error of errors.general">{{error}}</mat-error>
                </mat-form-field>
            </div>
            <div class="doscolumnas">
                <mat-form-field class="col_der">
                    <mat-label>Mail</mat-label>
                    <input type="mail" matInput name="mail" id="input-mail" [(ngModel)]="mail" placeholder="Email"
                           (change)="setModificable()" required>
                    <mat-error *ngFor="let error of errors.general">{{error}}</mat-error>
                </mat-form-field>
            </div>
        </div>


        <div class="titulo">
    <span>
        <mat-icon class="mat-icon">person</mat-icon>
            Persona Contacto
        </span>

        </div>
        <mat-divider></mat-divider>

        <div class="columnascontenedor">
            <div class="trescolumnas">
                <mat-form-field class="col_izq">
                    <mat-label>Nombre</mat-label>
                    <input type="text" matInput name="contacto" id="input-contacto" [(ngModel)]="nombreContacto"
                           (change)="setModificable()" placeholder="Nombre contacto" required>
                    <mat-error *ngFor="let error of errors.general">{{error}}</mat-error>
                </mat-form-field>
            </div>
            <div class="trescolumnas">
                        <mat-form-field class="col_med">
                    <mat-label>Email</mat-label>
                    <input type="email" matInput name="mailContacto" id="input-email-contacto" [(ngModel)]="mailContacto"
                           (change)="setModificable()" placeholder="Correo electrónico" required>
                            <mat-error *ngFor="let error of errors.general">{{error}}</mat-error>
                </mat-form-field>
            </div>
            <div class="trescolumnas">
                    <mat-form-field class="col_der">
                        <mat-label>Teléfono</mat-label>
                        <input type="tel" matInput name="telefonoContacto" id="input-tel-contacto"
                               (change)="setModificable()"
                               [(ngModel)]="telefonoContacto" placeholder="Teléfono de contacto" required>
                        <mat-error *ngFor="let error of errors.general">{{error}}</mat-error>
                    </mat-form-field>
            </div>

        </div>
        <mat-card-actions class="boton">
            <button mat-flat-button type="submit"  class="mat-primary full-width mb-16" [disabled]="modificable">Modificar</button>

        </mat-card-actions>

    </form>
</div>
</div>
