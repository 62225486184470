<div class="page-wrap h-full slate">
    <div class="session-form-hold">
        <!-- <mat-progress-bar mode="query" class="session-progress"></mat-progress-bar>-->
        <form #form="ngForm" [formGroup]="signupForm" (ngSubmit)="signup(form)" autocomplete="off">
            <mat-card class="registro-card">
                <img class="login_logo" mat-card-image src="/assets/Logo_azul.svg">
                <mat-card-content>
                    <div class="input_formulario">
                        <label class="label_login">{{ 'Email' }}</label>
                        <input id="email" class="campo_imput"
                               matInput
                               type="email"
                               name="email"
                               formControlName="email"
                               placeholder="E-mail"
                               value="">
                        <mat-error
                                *ngIf="signupForm.controls['email'].hasError('required') && signupForm.controls['email'].touched"
                                class="form-error-msg"> Mail es obligatorio
                        </mat-error>
                        <mat-error
                                *ngIf="signupForm.controls['email'].hasError('email') && signupForm.controls['email'].touched"
                                class="form-error-msg"> Mail erróneo
                        </mat-error>
                    </div>

                    <div class="input_formulario">
                        <mat-error *ngFor="let error of errors.password">{{ error }}</mat-error>
                        <label class="label_login">{{ 'Contraseña' }}</label>
                        <input class="campo_imput"
                               type="password"
                               name="password"
                               matInput
                               formControlName="password"
                               placeholder="Contraseña"
                               value="">
                        <mat-error
                                *ngIf="signupForm.controls['password'].hasError('required') && signupForm.controls['password'].touched"
                                class="form-error-msg"> Contraseña es obligatoria
                        </mat-error>
                    </div>

                    <div class="input_formulario">
                        <label class="label_login">{{ 'Confirmar Contraseña' }}</label>
                        <input class="campo_imput"
                               type="password"
                               name="confirmPassword"
                               matInput
                               formControlName="confirmPassword"
                               placeholder="Confirmar Contraseña"
                               value="">
                        <mat-error
                                *ngIf="signupForm.controls['confirmPassword'].hasError('required') && signupForm.controls['confirmPassword'].touched"
                                class="form-error-msg">Confirmar contraseña es obligatorio.
                        </mat-error>
                        <mat-error *ngIf="signupForm.controls['confirmPassword'].hasError('equalTo')"
                                   class="form-error-msg">Las contraseñas no coinciden.
                        </mat-error>
                    </div>

                    <div class="pb-16">
                        <mat-checkbox
                                name="agreed"
                                formControlName="agreed"
                                class="pb-16">He leído los <a href="https://cncdental.es/aviso-legal/">términos y
                            condiciones del servicio</a>.
                        </mat-checkbox>
                        <br>
                        <mat-error
                                *ngIf="signupForm.controls['agreed'].hasError('agreed') && signupForm.controls['agreed'].touched"
                                class="form-error-msg"> Debes aceptar y estar deacuerdo con los términos y
                            condiciones
                        </mat-error>
                        <small class="success" *ngIf="success!==''">{{ success }}</small>
                    </div>

                    <button mat-raised-button class="mat-primary full-width mb-16 boton-registro"
                            [disabled]="signupForm.invalid">
                        Registrarme
                    </button>
                    <div class="text-center">
                        <a [routerLink]="'/login'" class="text-center full-width">Ya dispongo de una cuenta</a>
                    </div>

                </mat-card-content>
            </mat-card>
        </form>
    </div>
</div>
