<be-dialog-shell [title]="title" (close)="closeDialog()" [closeButton]="!saving" [showFooter]="showSaveAndCancelButtons"
                 [className]="tags.size > 1 ? 'fixed-size' : null">
    <form autocomplete="off" (submit)="$event.preventDefault();" [formGroup]="formGroup">
        <mat-tab-group>
            <mat-tab *ngFor="let tag of tags" [label]="tag">
                <div class="tab-content">
                    <div class="tab-content" *ngIf="tag == 'Principal' ">
                        <be-edition-tab [propertiesToShow]="colsForTag(tag)" [formGroup]="formGroup"
                                        [formManager]="formManager"></be-edition-tab>
                    </div>
                 </div>
            </mat-tab>
            <mat-tab class="tab-content">
                <ng-template mat-tab-label class="tab-content">
                    Empresa
                </ng-template>
<!--                <div *ngIf="empresa!==null" class="tab-content">-->
<!--                        <form [formGroup]="empresaForm" class="contenedorForm" >-->
<!--                            <div class="tab-content">-->
<!--                                <div class="mat-form-field-flex"*ngFor="let key of empresaLabels; let i = index" [attr.data-index]="i">-->
<!--                                    <label class="label" for="{{key}}">{{key}}</label>-->
<!--                                    <input class="input mat-input-element" id="{{keys[i]}}" type="text"-->
<!--                                           placeholder="Escriba aquí"-->
<!--                                        formControlName="{{keys[i]}}">-->

<!--                                </div>-->
<!--                            </div>-->
<!--                        </form>-->
<!--                </div>-->

            </mat-tab>
            <mat-tab class="tab-content">
                <ng-template mat-tab-label class="tab-content">
                    Facturación
                </ng-template>

            </mat-tab>
            <mat-tab class="tab-content" *ngIf="direccionesEnvio!==null" >
                <ng-template mat-tab-label class="tab-content">
                    Direcciones de envío
                </ng-template>
                <div>
                    <form [formGroup]="enviosForm" class="contenedorForm" autocomplete="off">
                        <table mat-table class="table" [dataSource]="direccionesEnvio"  multiTemplateDataRows>

                            <!-- Provincia Column -->
                            <ng-container  class="cabeceraCell"  matColumnDef="provincia"  >
                                <th mat-header-cell class="headerCell" *matHeaderCellDef> Provincia </th>
                                <td  mat-cell *matCellDef="let element" > <span >{{element.provincia}}</span>
                            </ng-container>
                            <!-- Poblacion Column -->
                            <ng-container class="cabeceraCell" matColumnDef="poblacion"  >
                                <th mat-header-cell class="headerCell" *matHeaderCellDef> Población </th>
                                <td  mat-cell class="content" *matCellDef="let element" > <span >{{element.poblacion}}</span>
                            </ng-container>
                            <!-- CP Column -->
                            <ng-container class="cabeceraCell" matColumnDef="cp"  >
                                <th mat-header-cell class="headerCell" *matHeaderCellDef> CP </th>
                                <td  mat-cell class="content" *matCellDef="let element" > <span >{{element.cp}}</span>
                            </ng-container>
                            <!-- Direccion Column -->
                            <ng-container class="cabeceraCell" matColumnDef="direccion"  >
                                <th mat-header-cell class="headerCell" *matHeaderCellDef> Dirección </th>
                                <td  mat-cell class="content" *matCellDef="let element" > <span >{{element.direccion}}</span>
                            </ng-container>
                            <!-- Observaciones Column -->
                            <ng-container class="cabeceraCell" matColumnDef="observaciones"  >
                                <th mat-header-cell class="headerCell" *matHeaderCellDef> Observaciones </th>
                                <td  mat-cell class="content" *matCellDef="let element" > <span >{{element.observaciones}}</span>
                            </ng-container>
                            <!-- Botón editar Column -->
                            <ng-container class="cabeceraCell" matColumnDef="editar"  >
                                <th mat-header-cell class="headerCell" *matHeaderCellDef> Editar </th>
                                <td  mat-cell class="content" *matCellDef="let element" > <span ></span>

                                </td>
                            </ng-container>
                            <!-- Botón borrar Column -->
                            <ng-container class="cabeceraCell" matColumnDef="borrar"  >
                                <th mat-header-cell class="headerCell" *matHeaderCellDef> Borrar </th>
                                <td  mat-cell class="content" *matCellDef="let element" > <span ></span>

                                </td>
                            </ng-container>

                            <ng-container matColumnDef="expand">
                                <th mat-header-cell class="headerCellActions" *matHeaderCellDef aria-label="row actions"></th>
                                <td  mat-cell class="headerCellActions" *matCellDef="let element">


                                </td>

                            </ng-container>

                            <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                            <ng-container matColumnDef="expandedDetail" >
                                <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumnsExpand.length" >
                                    <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">

                                    </div>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumnsExpand"></tr>
                            <tr mat-row  *matRowDef="let element; columns: displayedColumnsExpand;"
                                [class.example-expanded-row]="expandedElement === element"
                                (click)="expandedElement = expandedElement === element ? null : element">

                            </tr>
                            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>



                        </table>
                    </form>
                </div>
            </mat-tab>
        </mat-tab-group>
    </form>
    <dialog-actions>
        <button mat-button (click)="closeDialog(true)" [disabled]="saving">Cancelar</button>
        <button mat-flat-button *ngIf="shouldManageSaving && modified" (click)="acceptDialog()" color="primary"
                [disabled]="!formGroup.valid || saving">
            <mat-icon>save</mat-icon>
            Guardar
        </button>
        <button mat-button *ngIf="!(shouldManageSaving && modified)" (click)="acceptDialog()"
                [disabled]="modified && !formGroup.valid">Aceptar
        </button>
    </dialog-actions>
</be-dialog-shell>
