<form #form="ngForm" (ngSubmit)="login(form)" autocomplete="off">
    <mat-card class="login-card">
        <img class="login_logo" mat-card-image src="/assets/Logo_Blanco.svg" alt="logo CNC">
        <h2>ACCESO CLIENTES</h2>
        <mat-card-header>

            <mat-card-title></mat-card-title>
            <!--<mat-card-subtitle *ngIf="!sessionExpired">¡Hola! Inicia sesión con tu nombre de usuario</mat-card-subtitle>-->
            <mat-card-subtitle *ngIf="sessionExpired">
                <mat-icon>info</mat-icon>
                La sesión previa ha expirado
            </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            <div class="input_formulario">
                <label class="label_login">Usuario</label>
                <input class="campo_imput" matInput name="user" id="input-user" ngModel placeholder="Usuario" required>
                <mat-error *ngFor="let error of errors.username">{{ error }}</mat-error>
            </div>

            <div class="input_formulario">
                <label class="label_login">Contraseña</label>
                <input class="campo_imput" matInput name="password" type="password" id="input-password"
                       ngModel placeholder="Contraseña" required>
                <mat-error *ngFor="let error of errors.password">{{ error }}</mat-error>
            </div>

            <div class="info" *ngIf="capsLock">
                <mat-icon>warning</mat-icon>
                BloqMayús podría estar activado.
            </div>
            <div class="error" *ngFor="let error of errors.general">
                <mat-icon>error</mat-icon>
                {{ error }}
            </div>
        </mat-card-content>

        <mat-card-actions>
            <button mat-flat-button type="submit" class="boton_login">Login</button>
        </mat-card-actions>

        <div class="links">
            <a [routerLink]="'/password-recovery'" class="">Olvidó la contraseña </a>
            <a [routerLink]="'/registro'" class="">Crear cuenta</a>
        </div>
    </mat-card>
</form>
