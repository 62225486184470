<mat-card class="recovery_card">
    <mat-card-title>
        Recuperación de contraseña
    </mat-card-title>
    <form [formGroup]="formGroup" (ngSubmit)="submit()" *ngIf="!done" autocomplete="off">
        <mat-card-content>
            <div class="input_formulario">
                <label class="label_login">{{ 'Email' }}</label>
                <input class="campo_imput" matInput type="email" name="email" formControlName="email" required>
                <mat-error
                        *ngIf="formGroup.get('email').hasError('email')">{{ 'Dirección de correo inválida' }}
                </mat-error>
                <mat-error *ngIf="formGroup.get('email').hasError('required')">{{ 'El campo es requerido.' }}
                </mat-error>
            </div>


        </mat-card-content>
        <mat-card-actions class="botonera">
            <button mat-flat-button color="primary" type="submit">Generar contraseña temporal</button>
        </mat-card-actions>
        <div class="links">
            <a [routerLink]="'/login'" class="">Volver</a>
        </div>
    </form>
    <div class="info sent" *ngIf="done">
        <p>Contraseña temporal enviada. Por favor, revise su bandeja de entrada.</p>
        <app-login>

        </app-login>
    </div>
</mat-card>
